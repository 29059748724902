import styles from '../App.css';
import {useEffect, useState} from 'react';

const ImageCarousel = () => {
    const imageList = [
        'https://via.placeholder.com/300x200',
        'https://via.placeholder.com/300x200/0000FF/808080',
        'https://via.placeholder.com/300x200/FF0000/FFFFFF',
      ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
        }, 3000);
   
        return () => {
            clearInterval(interval);
          };
        }, [imageList.length]);

    return (
        <div className="carousel-container">
       
        {imageList.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`carousel-img-${index}`}
            className={`carousel-image ${currentImageIndex === index ? 'visible' : 'hidden'}`}
           
          />
        ))}
        </div>
    )
}

export default ImageCarousel
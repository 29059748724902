import logo from './logo.svg';
import './App.css';
import Home from './components/home'
import Bio from './components/bio'
import Navbar from './components/navbar'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AudioControls from './components/audio_controls';
import {useEffect, useState} from 'react'

function App() {
  const [audioLevel, set_audioLevel] = useState(0);
  const [selectedChannel, set_selectedChannel] = useState('video')
  const [searchKeyword, setSearchKeyword] = useState('Corgi');

  const handleKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  };


  function handleLevelChange (level){
      set_audioLevel(level);
  }; 

  function handlePageChange (channel){
    set_selectedChannel(channel);
};

  useEffect(()=>{

    

  }, [handleLevelChange, handlePageChange, selectedChannel, handleKeywordChange])

  return (
    <div className= {'background-container'}>
    
    <Router>
  
    <div classname= {'background-container'}>
     <Routes>
        <Route path="/" element={<Home channel = {selectedChannel} keyword={searchKeyword}/>} />
        <Route path="/bio" element={<Bio/>} />
      </Routes>
      </div>
    </Router>
    <Navbar onPageChange={handlePageChange}/>
    
    </div>
  );
}

export default App;

import React, { useRef, useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import styles from '../App.css';
const AsciiPlayer = () => {
  async function getVideosFromStorage() { Storage.list('Video/', { pageSize: '1' })
  .then(result => {
    const filteredFiles = result.results.filter(file => file.size > 0);
    if (filteredFiles.length === 0) {
      console.error('No files found');
      return;
    }
    setFiles(filteredFiles);
    console.log('Files:', files)
  })
  .catch(err => console.error('Error fetching files:', err))
}
    const [files, setFiles] = useState([]);
    useEffect(() => {
        if (files.length === 0){

        getVideosFromStorage() 
        }
      }, [files]);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);

  const animationFrameId = useRef(null);
const maxRadius = 700;
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('video')) {
      const videoURL = URL.createObjectURL(file);
      setVideoFile(videoURL);
    }
  };
  const handlePlay = () => {
    const video = videoRef.current;
    video.playbackRate = 1;
    setPlaybackRate(1) // Set playback rate when playing
    if (video.paused) {
      setIsPlaying(true);

      video.playbackRate = playbackRate; // Set playback rate when playing
      video.play();
    } 
  };
  const handlePause = () => {
    const video = videoRef.current;
    video.playbackRate = 1;
    setPlaybackRate(1) 
    if (!video.paused) {
      setIsPlaying(false);

      video.pause();
    }
  };

  const toggleFastForward = () => {
    const newRate = 4;
    setPlaybackRate(newRate);
    if (videoRef.current) {
      videoRef.current.playbackRate = newRate;
      if (!isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }
  };
  useEffect(() => {
    if (files.length > 0){
      let _video = files[Math.round(files.length*Math.random())];
      Storage.get(_video.key).then(url => {setSelectedVideo(url)})
    
    console.log('Selected Video:', selectedVideo)
    }
  },[files]);

  const handleChangeVideo = () => {
    setSelectedVideo(null)
    let _video = files[Math.round(files.length*Math.random())];
    
    Storage.get(_video.key).then(url => {setSelectedVideo(url)})
  
    console.log('Selected Video:', selectedVideo)
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 1024;
    canvas.height = 720;
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.max(width, height) / 1.5;
    const video = videoRef.current;


    if (selectedVideo){
    video.src = selectedVideo // Set the video source when videoFile changes
    video.play();           // Optionally play the video automatically
    }


    const ctx = canvas.getContext('2d', {willReadFrequently: true});
   
    // handler for pause and play
  
       
    const drawFrame = () => {
      if (video.ended) 
      {
      return};
    
      ctx.drawImage(video, 0, 0, canvas.width/2.3, canvas.height/4);
      const imageData = ctx.getImageData(0, 0,  canvas.width, canvas.height);
      
      const data = imageData.data;
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = '4px monospace'; // Smaller font size
        ctx.fillStyle = 'white'; // Set the fill color to white
        

      let asciiImage = '';

      for (let i = 0; i < data.length; i += 4) {
        
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const avg = (r + g + b) / 3;
        if (avg == 0){
        asciiImage += ' ';
        }
        else if (avg < 60){
        asciiImage += '~';
        }
        else if (avg < 120){
        asciiImage += '=';
        }
        else if (avg < 180){
        asciiImage += '@';
        }
        else{
        asciiImage += '#';
        }
        if ((i / 4) % canvas.width === 0 && i !== 0) {
          const y = Math.floor((i / 4) / canvas.width) * 4;
          ctx.fillText(asciiImage, 0, y);
          asciiImage = '';
        }
      }
     
        animationFrameId.current = requestAnimationFrame(drawFrame);
     
        let gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, maxRadius);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');  // Fully visible in the center
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');  // Fully transparent at the edges

      // Apply the gradient as a mask
      ctx.globalCompositeOperation = 'destination-in';  // Apply the gradient as a mask
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);

      
      ctx.globalCompositeOperation = 'source-over';  // Reset composite mode for normal drawing
    };        
    //connect drawFrame to

    
    drawFrame();
    
    return () => {
      cancelAnimationFrame(animationFrameId.current);
      video.removeEventListener('play', drawFrame);
      if (videoFile) {
        URL.revokeObjectURL(videoFile);
      }
    };
  }, [selectedVideo]);

  return (
    <div >
    <div className = {'osc-container'} style = {{justifyContent: 'center'}}>
      
      <canvas ref={canvasRef} style = {{overflow: 'hidden', zIndex: '100', borderRadius: '20% 20% 20% 20% /30% 30% 30% 30%', width: '99%', height: '99%'}}/>
    </div>
    <div style = {{position: 'absolute', left: '81vw', width: '15vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
    {/* <input type="file" accept="video/*" onChange={handleFileChange} /> */}
    <button className={selectedVideo?'buttonOut':"buttonIn"} onClick={handleChangeVideo}> <img src = {'/icons/film.svg'} /> </button>

<button className={isPlaying && playbackRate == 1 ? 'buttonIn' : 'buttonOut'} onClick={handlePlay}>
  <img src = {'/icons/play.svg'} /> 

</button>
<button className={isPlaying ?'buttonOut' : 'buttonIn'} onClick={handlePause}> <img src = {'/icons/pause.svg'} /> </button>


        <button className={playbackRate==1?'buttonOut':"buttonIn"} onClick={toggleFastForward}> <img src = {'/icons/fast-forward.svg'} /> </button>

      <video id = "main_video" crossOrigin="anonymous" ref={videoRef} style={{ display: 'none'}} autoPlay loop />
    </div>
    </div>
  );
};

export default AsciiPlayer;

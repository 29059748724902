import React, { useState, useEffect } from 'react';

const numRows = 100;
const numCols = 100;
const cellSize = 6;

// Helper function to create a 2D grid with initial values
const createGrid = () => {
  const grid = [];
  for (let i = 0; i < numRows; i++) {
    grid.push(Array.from(Array(numCols), () => Math.random() > 0.7 ? 1 : 0));
  }
  return grid;
};

const GameOfLife = () => {
  const [grid, setGrid] = useState(() => createGrid());
  const [running, setRunning] = useState(true);

  useEffect(() => {
    if (running) {
      const updateGrid = () => {
        setGrid((prevGrid) => {
          // Create a copy of the grid to update the next generation
          const newGrid = JSON.parse(JSON.stringify(prevGrid));

          // Apply the rules of the Game of Life to update the next generation
          for (let i = 0; i < numRows; i++) {
            for (let j = 0; j < numCols; j++) {
              const neighbors = countNeighbors(prevGrid, i, j);
              const isAlive = prevGrid[i][j];

              if (isAlive && (neighbors < 2 || neighbors > 6 || neighbors == 4)) {
                newGrid[i][j] = 0; // Cell dies due to underpopulation or overpopulation
              } else if (!isAlive && neighbors == 3) {
                newGrid[i][j] = 1; // Cell becomes alive due to reproduction
              }
            }
          }

          return newGrid;
        });
      };

      // Start the animation loop
      const intervalId = setInterval(updateGrid, 2000);

      // Clean up the interval when the component unmounts or running is set to false
      return () => clearInterval(intervalId);
    }
  }, [running]);

  const countNeighbors = (grid, row, col) => {
    let count = 0;
    const neighbors = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1],           [0, 1],
      [1, -1], [1, 0], [1, 1]
    ];

    for (const [dx, dy] of neighbors) {
      const newRow = row + dx;
      const newCol = col + dy;

      if (newRow >= 0 && newRow < numRows && newCol >= 0 && newCol < numCols) {
        count += grid[newRow][newCol];
      }
    }

    return count;
  };

  const toggleCell = (row, col) => {
    setGrid((prevGrid) => {
      const newGrid = JSON.parse(JSON.stringify(prevGrid));
      newGrid[row][col] = prevGrid[row][col] ? 0 : 1; // Toggle cell value
      return newGrid;
    });
  };

  return (
    
    <div className = 'gol-container'>
      

      <div
        className="grid"
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${numCols}, ${cellSize}px)`,
        }}
      >
        {grid.map((row, rowIndex) =>
          row.map((cell, colIndex) => (
            <div
              key={`${rowIndex}-${colIndex}`}
              className={`cell ${cell ? 'alive' : ''}`}
              style={{
                width: cellSize,
                height: cellSize,
              }}
              onClick={() => toggleCell(rowIndex, colIndex)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default GameOfLife;

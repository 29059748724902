// Home.js
import React, { useState, useEffect } from 'react';
import '../App.css';
import ImageCarousel from './image_carousel';
import WhiteNoise from './white_noise'
import GameOfLife from './game_of_life';
import PexelsVideoPlayer from './video_player';
import ScreenOff from './screen_off';
import MusicPlayer from './music_player';
import MidiPlayer from './synth_component';
import AsciiPlayer from './ascii_player';
function Home(props) {

 
  
  useEffect(()=>{ console.log(props.channel)}, [props.channel])

  return (
    <div className='content-container'>
      
      {props.channel === 'off' && <ScreenOff/>}
      {props.channel === 'photos' && <ImageCarousel/>}
      {props.channel === 'game_of_life' && <GameOfLife/>}
      {props.channel === 'video' && <PexelsVideoPlayer keyword={props.keyword}/>}
      {props.channel === 'audio' &&  <MusicPlayer/>}
      {props.channel === 'synth' && <MidiPlayer/>}
      {props.channel === 'ascii'&& <AsciiPlayer/>}
      
    </div>
  );
}

export default Home;

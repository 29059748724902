import React from 'react'
import { useEffect } from 'react'   
import { useState } from 'react'
import '../App.css';


const SpeakerObject = ({text, onPpDone}) => {

    const [last_pp, set_last_pp] = useState(null)
    const [sp_count, set_spCount] = useState(0)
    const [pp_done, set_ppDone] = useState(false)

    function onPpDone (state){
        set_ppDone(state)
        console.log('done')
        return state
    }

function handleStop(){
    if (last_pp){
    for (let i = 0; i < last_pp.length; i++){
    const speechSynthesis = window.speechSynthesis;
    speechSynthesis.pause();
    console.log(speechSynthesis)
    speechSynthesis.cancel();
    }
  }   
}

  function handleSpeak(text){
    handleStop()
    const speechSynthesis = window.speechSynthesis;
    
 
    
    const voices = speechSynthesis.getVoices();
    const desiredVoice = voices.find((voice) => voice.lang === 'en-GB');
    // console.log(desiredVoice)
    
    if (text){
    const sentences = text.split(/(?<=[.!?])\s+/);
    
    set_last_pp(sentences)
    if (sp_count < sentences.length) {
    
    const utterance = new SpeechSynthesisUtterance(sentences[sp_count])
    utterance.rate = .8
    utterance.voice = desiredVoice
    utterance.onend = () => {
      // Callback function logic here
      set_spCount(sp_count+1)
      // Additional actions or function calls after speech synthesis ends
    };
    const pauseDuration = Math.floor(Math.random() * 0) + 1; // Generate a random pause duration between 1 and 5 seconds
    let pauseInterval;
    if (sp_count > 0) {pauseInterval = Math.floor(Math.random() * 5) + 2; }// Generate a random pause interval between 5 and 10 seconds
    else {pauseInterval = 0}
    
      setTimeout(() => {
      speechSynthesis.speak(utterance);
      setSentence(sentences[sp_count])
      }, pauseInterval * 1000);
    }
    else {
        set_ppDone(true)
    }
}          
    }
  const [sentence, setSentence] = useState(null);

  function onSentenceChange(newSentence){
    setSentence(newSentence)
  }



  useEffect(()=>{
    console.log("text is", text)
    handleSpeak(text)
  
},[sp_count])


    return(
        
        <div style = {{ position: 'absolute', top: '75vh', zIndex: '100', height: '20vh', width: '70vw'}} > 
                  <h2 style = {{color: 'white'}}> {sentence} </h2>

        </div>
    )

}
export default SpeakerObject; 
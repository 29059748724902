import React, { useRef, useEffect } from 'react';
import '../App.css';


function ScreenOff() {
  const canvasRef = useRef(null);

  

    // Animation loop function
    

  return <div className = 'gol-container'>
    <div className = 'off-container'></div>
  </div>;
}

export default ScreenOff;

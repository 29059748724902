import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import RotaryDial from './rotary_dial';

function Navbar({onPageChange}) {
  const [selectedPage, setSelectedPage] = useState('off');
  const [showInstruction, setShowInstruction] = useState(false);
  const handlePageChange = (page) => {
    setSelectedPage(page);
  };
  const tempVal = 1;
  const [rotationAngle, setRotationAngle] = useState(0);

  
  const handleRotationChange = (angle) => {
    
    if (angle === 0){
      setSelectedPage('off')
    }
    
    
    if (angle === 60){
      setSelectedPage('video')
    }

    if (angle === 120){
      setSelectedPage('audio')
    }
    if (angle === 180){
      setSelectedPage('synth')
    }
    if (angle === 240){
      setSelectedPage('ascii')
    }
    if (angle > 240){
      setSelectedPage('off')
    }
  };

  

  useEffect (()=>{
    
    onPageChange(selectedPage)
    if (selectedPage === 'off'){
      
      setTimeout(() => {setShowInstruction(true)},5000)
    }
    else{
      setShowInstruction(false)
    }
  }, [handlePageChange, handleRotationChange, showInstruction])


  return (
    <div className="navbar-container">
        <div className={selectedPage=='off'?'channel-readout':'channel-readout-on'}>
          <div className='channel-text' key ={selectedPage} >  {selectedPage=='off'?'':selectedPage.replace(/_/g, ' ')} 
          </div>

          </div>

        <RotaryDial onRotationChange={handleRotationChange}/>
        {showInstruction && selectedPage=="off"?<div className = 'channel-text-small' style = {{height: '20px', marginTop: '10px'}}>click to turn</div>:<div style = {{height: '20px', marginTop: '10px'}}></div>}

        <p className = 'channel-text-small'> cvad.tv<br/> is a digital installation exploring creative applications of intermodal AI systems. Powered by Chat-GPT + Pexels</p>

    </div>  
  );
}

export default Navbar;

import React, { useRef, useEffect } from 'react';
import {Analyser} from 'tone';
const Oscilloscope = ({ audioNode }) => {
  const canvasRef = useRef(null);
    const analyserRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.max(width, height) / 1.5;
    const analyzer = new Analyser('waveform', 4096);
    audioNode?.connect(analyzer);
    const maxRadius = Math.max(width, height) / 2;  // Use max to cover the entire canvas

    const draw = () => {
      requestAnimationFrame(draw);
      const values = analyzer.getValue();
      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.lineJoin = 'round';
      context.lineWidth = 2;
      context.strokeStyle = '#0f0';
      context.moveTo(0, (values[0] + 0.5) * height);
    const maxRadius = Math.max(width, height) / 2;  // Use max to cover the entire canvas

      for (let i = 1, len = values.length; i < len; i++) {
        const val = (values[i] + 0.5) * height;
        context.lineTo(i * (width / len), val);
      }
      context.stroke();
      let gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, maxRadius);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');  // Fully visible in the center
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');  // Fully transparent at the edges

      // Apply the gradient as a mask
      context.globalCompositeOperation = 'destination-in';  // Apply the gradient as a mask
      context.fillStyle = gradient;
      context.fillRect(0, 0, width, height);

      
      context.globalCompositeOperation = 'source-over';  // Reset composite mode for normal drawing
    };

    draw();

    return () => {
      analyzer.disconnect();
    };
  }, [audioNode]);

  useEffect(() => {
    analyserRef.current = new Analyser('waveform', 4096);
    audioNode?.connect(analyserRef.current);
    return () => {
      analyserRef.current.disconnect();
    };
  }, [audioNode]);

  return <canvas style={{ overflow: 'hidden', borderRadius: '100px'}} ref={canvasRef} width="1600px" height="900px" />;
};

export default Oscilloscope;

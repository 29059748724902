import React, { useState, useEffect } from 'react';

function RotaryDial({onRotationChange}) {
  const [rotationAngle, setRotationAngle] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMousePosition, setInitialMousePosition] = useState(null);
    const tempVal = 0;
  useEffect(() => {
    function handleMouseMove(event) {
      if (!isMouseDown) return;

      const currentMousePosition = {
        x: event.clientX,
        y: event.clientY
      };

      const rotation = calculateRotation(currentMousePosition);
      setRotationAngle(rotation);
      onRotationChange(rotation);
      //set rotation angle to 0 if its greater than 180
 
      
    }
   
    function handleMouseUp() {
       
      setIsMouseDown(false);
     
     
    }



    return () => {
 
    };
  }, [isMouseDown, onRotationChange, rotationAngle]);

  function handleMouseDown(event) {
    setIsMouseDown(true);
    let tmpVal = (rotationAngle + 60) %360 
    setRotationAngle(tmpVal)
    onRotationChange(tmpVal)
    console.log(rotationAngle)
  }

  function calculateRotation(currentMousePosition) {
    if (!initialMousePosition) return 0;

    const deltaX = currentMousePosition.x - initialMousePosition.x;
    const deltaY = currentMousePosition.y - initialMousePosition.y;

    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
    const angleIn30DegreesIncrements = Math.round(angle / 60) * 60;

    return angleIn30DegreesIncrements;
  }

  const divStyle = {
    transform: `rotate(${rotationAngle}deg)`
  };

  return (
    <div
      className="rotary-dial"
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      style={divStyle}
    >
    <div className = 'rotary-dial-indicator'>

    </div>
    </div>
  );
}

export default RotaryDial;
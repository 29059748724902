import React, { useState, useEffect, useRef } from 'react';
import { PolySynth, getTransport, Synth, Part, Volume, JCReverb, Limiter, Analyser } from 'tone';
import { Midi } from '@tonejs/midi';
import Oscilloscope from './oscilloscope';
import CircularOscilloscope from './circular_scope';
function MidiPlayer() {
    const [isLoaded, setLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [oscType, setOscType] = useState('sine');
    const [oscType2, setOscType2] = useState('sine');
    const [volume, setVolume] = useState(-18);
    const [volume2, setVolume2] = useState(-18);
    const [octave, setOctave] = useState(1);
    const [octave2, setOctave2] = useState(1);
    const [detune, setDetune] = useState(0);
    const synthRef = useRef(null);
    const synthRef2 = useRef(null);
    const volumeRef = useRef(null);
    const volumeRef2 = useRef(null);
    const reverbRef = useRef(null);
    const [midi, setMidi] = useState(null);
    const analyserRef = useRef(null);
    const limiterRef = useRef(null);
    const [attack, setAttack] = useState(0.1);
    const [decay, setDecay] = useState(0.1);
    const [sustain, setSustain] = useState(0.5);
    const [release, setRelease] = useState(1.0);
    const [tempo, setTempo] = useState(120);
    const [settingsDrawer, setSettingsDrawer] = useState(true);
    const [selectedScope, setSelectedScope] = useState(0);

    useEffect(() => {
        loadMidi();
        synthRef.current = new PolySynth(Synth, {
            oscillator: { type: oscType }
        })
        volumeRef.current = new Volume(volume)

        synthRef2.current = new PolySynth(Synth, {
            oscillator: { type: oscType2 }
        })
        volumeRef2.current = new Volume(volume2)
        reverbRef.current = new JCReverb({ roomSize: .98, wet: .5 });
        analyserRef.current = new Analyser('waveform', 1024);

        synthRef.current.connect(volumeRef.current); // Connect the synth to the volume control
        synthRef2.current.connect(volumeRef2.current); // Connect the synth to the volume control
        limiterRef.current = new Limiter(0).toDestination();

        volumeRef.current.connect(reverbRef.current);
        volumeRef2.current.connect(reverbRef.current);
        limiterRef.current.connect(analyserRef.current);
        reverbRef.current.connect(limiterRef.current);
        return () => {
            synthRef.current.dispose();
            analyserRef.current.disconnect();

            getTransport().cancel();
        };
    }, []);

    useEffect(() => {
        if (synthRef.current) {
            synthRef.current.set({
                oscillator: { type: oscType },
                frequency: 440,
                detune: detune,
                envelope: {
                    attack: attack,
                    decay: decay,
                    sustain: sustain,
                    release: release
                }
            });
        }
        if (synthRef2.current) {
            synthRef2.current.set({
                oscillator: { type: oscType2 },
                frequency: 440,
                detune: -1 * detune,
                envelope: {
                    attack: attack,
                    decay: decay,
                    sustain: sustain,
                    release: release
                }
            });
        }
        if (volumeRef.current) {
            volumeRef.current.volume.value = volume;
        }
        if (volumeRef2.current) {
            volumeRef2.current.volume.value = volume2;
        }
        getTransport().bpm.rampTo(tempo, .001);
    }, [oscType, octave, octave2, detune, attack, decay, sustain, release, volume, volume2, oscType2, tempo]);

    const handleTypeChange = (e) => setOscType(e.target.value);
    const handleTypeChange2 = (e) => setOscType2(e.target.value);

    const handleVolumeChange = (e) => setVolume(e.target.value);
    const handleVolumeChange2 = (e) => setVolume2(e.target.value);

    const handleOctaveChange = (e) => setOctave(e.target.value * 440);
    const handleOctaveChange2 = (e) => setOctave2(e.target.value * 440);

    const handleDetuneChange = (e) => setDetune(Number(e.target.value));

    const handleAttackChange = (e) => setAttack(Number(e.target.value));
    const handleDecayChange = (e) => setDecay(Number(e.target.value));
    const handleSustainChange = (e) => setSustain(Number(e.target.value));
    const handleReleaseChange = (e) => setRelease(Number(e.target.value));
    const handleTempoChange = (e) => setTempo(Number(e.target.value));


    const loadMidi = async () => {
        try {
            let midiNum = Math.round(12 * Math.random());
            console.log("midiNum", midiNum)
            const response = await fetch(`/midi_examples/${midiNum}.mid`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.arrayBuffer();
            const midi = new Midi(data);
            setMidi(midi);
            setLoaded(true);
        } catch (error) {
            console.error('Failed to load MIDI file:', error);
            // Here, you might want to update the state to show an error message to the user.
        }
    }
    const handlePlay = () => {
        if (!isPlaying) {
            if (midi) {
                const part = new Part((time, note) => {
                    // Use synthRef.current to trigger the note
                    synthRef.current.triggerAttackRelease(note.name, note.duration, time, note.velocity);
                }, midi.tracks.flatMap(track => track.notes.map(note => ({
                    time: note.time,
                    name: note.name,
                    duration: '16n',  // or note.duration if it varies and you want to respect it
                    velocity: note.velocity
                })))).start(0);
                const part2 = new Part((time, note) => {
                    // Use synthRef.current to trigger the note
                    synthRef2.current.triggerAttackRelease(note.name, note.duration, time, note.velocity);
                }, midi.tracks.flatMap(track => track.notes.map(note => ({
                    time: note.time,
                    name: note.name,
                    duration: '16n',  // or note.duration if it varies and you want to respect it
                    velocity: note.velocity
                })))).start(0);

                getTransport().start();
                setIsPlaying(true);

                return () => {
                    part.stop();
                    part2.stop();
                    part.dispose(); // Clean up the part when not needed anymore
                    part2.dispose(); // Clean up the part when not needed anymore
                };
            }
        } else {
            getTransport().start();
            setIsPlaying(true);
        }
    };

    const handleStop = () => {
        loadMidi();
        getTransport().stop();
        getTransport().cancel(); // This cancels scheduled events and parts
        setIsPlaying(false);
    };

    // class ErrorBoundary extends React.Component {
    //     constructor(props) {
    //         super(props);
    //         this.state = { hasError: false };
    //     }
    
    //     static getDerivedStateFromError(error) {
    //         return { hasError: true };
    //     }
    
    //     componentDidCatch(error, errorInfo) {
    //         console.error("Error caught by Error Boundary:", error);
    //     }
    
    //     render() {
    //         if (this.state.hasError) {
    //             console.log("Error caught by Error Boundary", this.props.children);
    //         }
    //         return this.props.children;
    //     }
    // }
    return (
        <div className = {'osc-container'} style = {{overflow: 'visible'}}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', }}>
                
                {analyserRef.current && 
                (selectedScope == 1)?(<CircularOscilloscope audioNode={analyserRef.current} />):(<Oscilloscope audioNode={analyserRef.current} />)
                }
            <div style = {{display: 'flex', minWidth: 'fit-content', color: 'whitesmoke', fontFamily: 'Courier New', justifyContent: 'space-evenly', position: 'absolute', bottom: '0', zIndex: '100', width: '15vw', left: '77vw', display: 'flex', height: '30vh', flexDirection: 'column', backgroundColor: "#493633", padding: '10px', borderRadius: '10px'}}>
                <span> Scope Type: <button onClick= {() => setSelectedScope(0)}> Line </button> <button onClick= {() => setSelectedScope(1)}> Circle</button> </span> 
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly' }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label> oscillator 1</label>
                        <select value={oscType} onChange={handleTypeChange}>
                            <option value="sine">Sine</option>
                            <option value="square">Square</option>
                            <option value="triangle">Triangle</option>
                            <option value="sawtooth">Sawtooth</option>
                        </select>
                        <label>Volume: {volume}</label>
                        <input type="range" min="-60" max="-6" step="0.01" value={volume} onChange={handleVolumeChange} />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <label> oscillator 2</label>

                        <select value={oscType2} onChange={handleTypeChange2}>
                            <option value="sine">Sine</option>
                            <option value="square">Square</option>
                            <option value="triangle">Triangle</option>
                            <option value="sawtooth">Sawtooth</option>
                        </select>
                        <label>Volume 2: {volume2}</label>
                        <input type="range" min="-60" max="-6" step="0.01" value={volume2} onChange={handleVolumeChange2} />
                    </div>
                </div>



                <label>Tempo: {tempo}</label>
                <input type="range" min="20" max="300" step="1" value={tempo} onChange={handleTempoChange} />

                {/*<label>Octave</label>

                 <select value={octave} onChange={handleOctaveChange} >
                        <option value={.125}>-3</option>
                        <option value={.25}>-2</option>

                        <option value={.5}>-1</option>

                        <option value={1}>0</option>

                        <option value={2}>+1</option>

                        <option value={4}>+2</option>
                        <option value={8}>+3</option>
                        </select>

                <label>Octave2</label>

                <select value={octave2} onChange={handleOctaveChange2} >   
                        <option value={.125}>-3</option>
                        <option value={.25}>-2</option>

                        <option value={.5}>-1</option>

                        <option value={1}>0</option>

                        <option value={2}>+1</option>

                        <option value={4}>+2</option>
                        <option value={8}>+3</option>
                        </select> */}
                <label>Detune: {detune}</label>

                <input type="range" min="0" max="30" step="0.001" value={detune} onChange={handleDetuneChange} />
                <label>Attack: {attack}</label>
                <input type="range" min="0" max="1" step="0.01" value={attack} onChange={handleAttackChange} />
                <label>Decay: {decay}</label>
                <input type="range" min="0" max="1" step="0.01" value={decay} onChange={handleDecayChange} />
                <label>Sustain: {sustain}</label>
                <input type="range" min="0" max="1" step="0.01" value={sustain} onChange={handleSustainChange} />
                <label>Release: {release}</label>
                <input type="range" min="0" max="10" step="0.01" value={release} onChange={handleReleaseChange} />



                {!isPlaying && <button onClick={handlePlay} disabled={isPlaying}>
                Play
            </button>}
            {isPlaying && 
            <button onClick={handleStop} disabled={!isPlaying}>
                Stop
            </button>
}
            </div>
     
            </div>
        </div>
    );
}

export default MidiPlayer;

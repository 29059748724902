import React, { useRef, useEffect } from 'react';
import { Analyser } from 'tone';

const CircularOscilloscope = ({ audioNode }) => {
  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
    const animationFrameId = useRef(null); // Ref to store the requestAnimationFrame ID

  useEffect(() => {
    if (!audioNode) {
        console.warn("CircularOscilloscope: audioNode is undefined.");
        return;
      }
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const maxRadius = Math.max(width, height) / 2;  // Use max to cover the entire canvas

    const analyzer = new Analyser('waveform', 4096);
    audioNode?.connect(analyzer);
    analyserRef.current = analyzer;

    const drawCircle = () => {
        animationFrameId.current = requestAnimationFrame(drawCircle);
              const imageData = context.getImageData(0, 0, width, height);

        // Draw the previous Frame
        context.clearRect(0, 0, width, height);
     
      // Draw the circular waveform
      const values = analyzer.getValue();
      context.beginPath();
      context.lineJoin = 'round';
      context.lineWidth = 2;
      context.strokeStyle = '#0f0';

      values.forEach((value, i, arr) => {
        const angle = (i / arr.length) * 2 * Math.PI;
        const amplitude = (value + 1) / 2;  // Normalize amplitude
        const x = centerX + amplitude * maxRadius * Math.cos(angle);
        const y = centerY + amplitude * maxRadius * Math.sin(angle);

        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
      });

      context.closePath();
      context.stroke();

      // Create a radial gradient to use as a mask
      let gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, maxRadius);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');  // Fully visible in the center
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');  // Fully transparent at the edges

      // Apply the gradient as a mask
      context.globalCompositeOperation = 'destination-in';  // Apply the gradient as a mask
      context.fillStyle = gradient;
      context.fillRect(0, 0, width, height);

      
      context.globalCompositeOperation = 'source-over';  // Reset composite mode for normal drawing
    };

    drawCircle();

    return () => {
        cancelAnimationFrame(animationFrameId.current);

        if (analyserRef.current) {
            analyserRef.current.disconnect();
          }
          if (audioNode) {
            audioNode.disconnect();
          }
    };
  }, [audioNode]);

  return <canvas style={{ overflow: 'hidden', borderRadius: '100px'}} ref={canvasRef} width="1000" height="750" />;
};

export default CircularOscilloscope;

import React, { useState, useEffect } from 'react';
import { createClient } from 'pexels';

import ImagePicker from './image_picker';
import WhiteNoise from './white_noise';
import WhiteNoiseOverlay from './white_noise_overlay';
import SpeakerObject from './speaker';

const PexelsVideoPlayer = ({ keyword }) => {
  const client = createClient(process.env.REACT_APP_PEXELS_KEY);
  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [paragraphText, setParagraphText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topic = await sendRequest();
        const videos = await searchVideos(topic);
        const description = await sendFullRequest(topic);
        setParagraphText(description);
        setVideos(videos);
       
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (videos.length > 0 && paragraphText) {
      setIsLoading(false);
    }
  }, [paragraphText, videos])

  const sendRequest = async () => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: 'please give me an animal as a single word' }],
      }),
    });
    const data = await response.json();
    return data.choices[0].message.content;
  };

  const sendFullRequest = async (topic) => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: `please write a few paragraphs about this animal: ${topic}` }],
        max_tokens: 500,
      }),
    });
    const data = await response.json();
    return data.choices[0].message.content || ' ';
  };

  const searchVideos = async (query) => {
    try {
      const response = await client.videos.search({ query });
      return response.videos;
    } catch (error) {
      console.error('Error searching videos:', error);
      return [];
    }
  };

  return (
    <div>
      {isLoading ? (
        <WhiteNoise />
      ) : (
        <div className='gol-container'>
          {paragraphText && <SpeakerObject text={paragraphText} />}
          <ImagePicker video_list={videos} onSelectVideo={(video) => console.log('Video selected:', video)} />
          <WhiteNoiseOverlay />
        </div>
      )}
    </div>
  );
};

export default PexelsVideoPlayer;

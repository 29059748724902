import '../App.css';

import { createClient } from 'pexels';
import React, { useState, useEffect } from 'react';
import ImagePicker from './image_picker_music.js'
import WhiteNoise from './white_noise';
import WhiteNoiseOverlay from './white_noise_overlay';
import SpeakerObject from './speaker'



const MusicVideoPlayer = ({ keyword }) => {

  const client = createClient('8Nm4TcpmVU3Q67ENh0ZuYZUsgMH91tw91qef3OtenIEqo8aSg7z3IPW7');
  const query = 'Nature';
  const [didFail, setDidFail] = useState(false)
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchKeyword, setSearchKeyword] = useState(null)
  const [hasVideos, setHasVideos] = useState(false);
  const [hasTopic, setHasTopic] = useState(false);
  const [hasDescription, setHasDescription] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [previousTopic, setPreviousTopic] = useState(null);
  const [currentSentence, setCurrentSentence]  = useState(null);
  const handleSentenceChange = (sentence) => {
    setCurrentSentence(sentence)
  }
  const handleSelectVideo = (video) => {
    setSelectedVideo(video);
    // Do something with the selected video
  };
  const [paragraphText, setParagraphText] = useState(null);

  const [responseText, setResponseText] = useState(null);
  const [inputText, setInputText] = useState('');
  // const handleInputChange = (event) => {
  //   setInputText(event.target.value);
  // };
  const sendRequest = async () => {
    
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      cache: "no-store",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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', // Replace with your ChatGPT API key
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo', // Specify the model you want to use
        messages: [
          { role: 'user', content: 'please give me an animal'},
        ],
      }),
      
    });

    const data = await response.json();
    const generatedText = data.choices[0].message.content
   
    return (generatedText)
  };
  const sendFullRequest = async (topic) => {
    //handleStop();
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      cache: "no-store",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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', // Replace with your ChatGPT API key
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo', // Specify the model you want to use
        messages: [
          { role: 'user', content: `please write a few paragraphs about this animal: ' + ${topic}`},
        ],
        max_tokens:500,
      }),
    });

    const data = await response.json();
    
    const generatedText = data.choices[0].message.content?data.choices[0].message.content:' ';
   
    
    return generatedText
    
  //   const searchVideos = async (_keyword) => {
  //   try {
  //     //console.log(_keyword)
  //    // console.log("30394093409")
  //     const response = await client.videos.search({ query: _keyword });
  //     //console.log(response)
  //     setVideos(response.videos);
      
      
  //     setHasVideos(true);
      
  //     //console.log(videos)
  //     return response.videos
  //   } catch (error) {
  //     setDidFail(true)
  //     console.error('Error searching videos:', error);
  //   }
    
   
  // };
   
    
   
    
    
  };
  const searchVideos = async (_keyword) => {
    try {
      console.log("Keyword:")
      console.log(_keyword)
      
      const response = await client.videos.search({ query: _keyword });
      //console.log(response.videos)
      
     
      
     
      
      
      
      
    
      
      
      return response.videos
    } catch (error) {
      setDidFail(true)
      console.error('Error searching videos:', error);
    }
    
   
  };
 

  // function checkDone(){
  //   let val = 0
  //   if (hasTopic){
  //     val = val + 1
  //   }
  //   console.log(val)
  //   if (hasDescription){
  //     val = val + 1
  //   }
  //   console.log(val)
  //   if (hasVideos){
  //     val = val + 1
  //   }
  //   console.log(val)
  //   if (val === 3 ){
  //     setIsLoading(false)
  //   }
    
  // }
  const fetchData = async () => {
    try {
    
      const _videos = await searchVideos(keyword);
      
      if (_videos.length < 1) {
        let temp_videos = await searchVideos('Dance')
        setVideos(temp_videos)
      }
      else{
      setVideos(_videos);
      }
     
      setIsLoading(false)
     
    } catch (error) {
      console.error(error);
      // Handle error
      
    }
  };
  const [watchCount, setWatchCount] = useState(0)

  function changeTopic(){setIsLoading(true); setWatchCount(watchCount + 1)}
  
  
    


  useEffect(() => {
   
    

   

    
    
    
    const isReady = fetchData();
    
   
    
   
        
    
  
  },[]);

  
  // async function StartSequence() {
  //   if (responseText === null){
  //   let topic = await sendRequest();
  //   setResponseText(topic)
  //   setHasTopic(true)
  //   if (topic !== null) {
  //     if (paragraphText === null){
  //       let pp = await sendFullRequest(topic);
  //       setParagraphText(pp)
  //       setHasDescription(true);
        
  //       }
  //       if (videos === null){
  //       let vids = await searchVideos(topic);
  //       setVideos(vids);
  //       setHasVideos(true);
        
  //       }
       
  //     }
  //   }
    
   
      
  // }
 

  
  // const handleSubmit = (event) => {
    
  //   setIsLoading(true);
  //   sendRequest().then(()=>{
  //     searchVideos(responseText).then(()=>{
  //       if (videos.length < 1 || !videos){
          
  //         setDidFail(true)
  //       }
  //         sendFullRequest().then(()=>{
            
  //           setIsLoading(false);
  //         });
       
        
  //     })
  //   })
    
  // };

  // const simSubmit = () => {
  //   setIsLoading(true);
  
  //   console.log("#1");
  //   setPreviousTopic(responseText);
  
  //   sendRequest().then(()=> {
  //     searchVideos(responseText).then(()=>{
  //      sendFullRequest().then(()=>{
  //       setIsLoading(false);
  //      })
       
  //     })

  //   })
  //   console.log("#2");
   
  
   
  //   // Run searchVideos and sendFullRequest after sendRequest completes
    
  // };
  
  
 
 
  function logStuff() {
    console.log(paragraphText)
    console.log(videos)
    //const voices = speechSynthesis.getVoices();
    


  }
 
  return (
    <div>
    {isLoading &&
    <div>
    <WhiteNoise/>
   
    </div>} 
    {!isLoading &&
    <div className = 'gol-container'> 
     
      

     
     
     
    
      {paragraphText && 
      <SpeakerObject
        text = {paragraphText}
        onPpDone={changeTopic}
         
      />
      }
      
      <ImagePicker
        video_list={videos}
        onSelectVideo={handleSelectVideo}
       

      />
      
      <div style = {{backgroundColor: "transparent", position: 'absolute', zIndex: '100', left: '5vw', top: '50vh'}}>
      
      
     
       
     
     
      </div>
      
      <WhiteNoiseOverlay/>
      
      </div>}
      </div>
      );
 
};

export default MusicVideoPlayer;
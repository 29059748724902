import React, { useState, useEffect, useRef } from 'react';
import '../App.css';

const ImagePicker = ({ video_list }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const handleLoadedMetadata = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      setDuration(videoElement.duration);
      videoElement.playbackRate = Math.random()+.5
      videoElement.loop = 1
    }
  };

   
  

  

  useEffect(() => {
    
    const playNextVideo = () => {
      setCurrentVideoIndex((prevIndex) => {
        // Generate a random index different from the current one
        let newIndex;
        do {
          newIndex = Math.floor(Math.random() * video_list.length);
        } while (newIndex === prevIndex);

        return newIndex;
      });
    };
    const randomVal = 2000*(Math.round(Math.random(10000)))
    const videoInterval = setInterval(playNextVideo, 10000); // Change the interval duration as needed

    return () => {
      clearInterval(videoInterval);
    };
  }, [video_list, duration]);

  useEffect(() => {
    
    // Reset the video index when the video list changes
    setCurrentVideoIndex(0);
   
  }, [video_list]);

  return (
    <div style = {{width: '100%', height: '100%'}}>
      
      {video_list && video_list.length > 0 && (
        <video 
        style={{width: '100%',height: '100%', objectFit: 'fill', filter: 'saturate(200%) grayscale(10%) sepia(20%) blur(5px)' }}
        preload="auto"
        src={video_list[currentVideoIndex].video_files[0].link} 
        autoPlay 
        muted
        onLoadedMetadata={handleLoadedMetadata}
        ref={videoRef}
        loop
        
        />
      )}
    </div>
  );
};

export default ImagePicker;



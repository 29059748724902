import React, { useState, useEffect, useRef } from 'react';
import { Storage } from 'aws-amplify';
import ImagePicker from './image_picker';
import WhiteNoise from './white_noise';
import MusicVideoPlayer from './video_player_music';

function MusicPlayer() {
  const [files, setFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentFileName, setCurrentFileName] = useState(null)

  const audioRef = useRef(null);

  const drawVisualizer = (analyser, ctx) => {
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);

      ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const barWidth = (ctx.canvas.width / bufferLength) * 2.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 2;

        ctx.fillStyle = 'rgb(' + (barHeight + 100) + ',50,50)';
        ctx.fillRect(x, ctx.canvas.height - barHeight / 2, barWidth, barHeight);

        x += barWidth + 1;
      }
    };

    draw();
  };


  useEffect(() => {
    Storage.list('Music/')
      .then(result => {
        const filteredFiles = result.results.filter(file => file.size > 0);
        setFiles(filteredFiles);
        
      })
      .catch(err => console.error('Error fetching files:', err))
  
      
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', playRandomFile);
      audioRef.current.play();
      // Cleanup listener when unmounting
      return (
      
        audioRef.current.removeEventListener('ended', playRandomFile)
      );
    }
    
      if (files.length > 0){
        playRandomFile()
       }
  }, [files, currentFile]);



  const playFile = (fileKey) => {
    Storage.get(fileKey)
      .then(url => {
        setCurrentFile(url);
      })
      .catch(err => console.error('Error fetching file:', err));
  };

  const playRandomFile = () => {
    const randomIndex = Math.floor(Math.random() * files.length);
    playFile(files[randomIndex].key);
    setCurrentFileName(files[randomIndex].key.split('Music/')[1].split('.').slice(0, -1).join('.')
    )
  };

  return (
    <div>
       
        <MusicVideoPlayer keyword = {currentFileName}/>
        
        <div style = {{position: 'absolute', left: '80vw', top: '60vh'}} >
        <button onClick = {playRandomFile}> NEXT </button>
        <h1 style = {{
            opacity: '1',
            color: 'white',
            fontFamily: ' Courier New, Courier, monospace', capitalize: 'true', fontSize: '30pt' }}> {currentFileName}</h1>

      {currentFile && <audio ref={audioRef} controls src={currentFile} />} </div>
    </div>
  );
}

export default MusicPlayer;


/* <ul>
        {files.map((file, index) => (
          <li key={index} onClick={() => playFile(file.key)}>
           {file.key.split('Music/')[1].split('.').slice(0, -1).join('.')}

          </li>
        ))}
      </ul> */
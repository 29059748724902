import React, { useRef, useEffect } from 'react';

function WhiteNoiseOverlay() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let animationFrameId;
    
    // Function to generate random noise pixel values
    function generateNoiseData(width, height) {
      const data = context.createImageData(width, height);
      let prev_value;
    
      for (let i = 0; i < data.data.length; i += 4) {
        const value = Math.round(Math.random() * 256) * 0.01 + (prev_value || 0) * 0.99; // Random value between 0 and 255
        prev_value = value;
    
        data.data[i] = Math.floor(value);     // Red component
        data.data[i + 1] = Math.floor(value); // Green component
        data.data[i + 2] = Math.floor(value); // Blue component
        data.data[i + 3] = 150;   // Alpha component (fully opaque)
      }
    
      return data;
    }

    // Animation loop function
    function animate() {
      const { width, height } = canvas;

      const noiseData = generateNoiseData(width, height);
      context.putImageData(noiseData, 0, 0);

      // Request the next animation frame
      animationFrameId = requestAnimationFrame(animate);
    }

    // Start the animation loop
    animate();

    // Clean up the animation frame when the component unmounts
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas className = 'noise-container-overlay' ref={canvasRef} />;
}

export default WhiteNoiseOverlay;
